import firebase from 'firebase/app'
import mail from '@/firestore/mail/msg'

export default async function sendResisteredMail(payload) {
  try {
    const { mailaddress } = payload

    const index1 = mail.findIndex(v => v.id === 2)
    const msg = mail[index1]
    let text = String(msg.text)
    text = String(text).replace(/MAIL_ADDRESS/g, mailaddress)

    // const mailTo = ['ken.okuno@gmail.com']
    const mailTo = ['k.suzuki@collect-agency.com', 't.fujibuchi@collect-agency.com', 'y.hayashi@collect-agency.com']
    mailTo.push(mailaddress)
    // mailTo.push(prote_mail)

    const content = {
      bcc: mailTo,
      message: {
        subject: msg.title,
        text,
      },
      type: 'consumer',
      category: 'registerUserInfo_notification',
    }

    await firebase.firestore().collection('mail').add(content)
    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: 'ユーザ登録完了のメールが配信されませんでした。',
    }
    return response
  }
}
