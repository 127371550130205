<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->

          <h2 class="brand-text text-primary ml-1">
            転職コレクション
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          ようこそ、転コレへ！
        </b-card-title>
        <b-card-text class="mb-2">
          <div class="">
            パスワードを入力して｢登録する｣ボタンを押して下さい。
          </div>
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
          >

            <!-- email -->
            <b-form-group
              label-for="mail"
              label="Mail"
            >
              <validation-provider
                name="Mail"
              >
                <b-form-input
                  id="mail"
                  v-model="mailaddress"
                  name="login-email"
                  placeholder="1234"
                  disabled
                />
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge input_"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              v-if="status == 1"
              variant="primary"
              block
              :disabled="invalid"
              style="margin: 10% 0;"
              @click="register"
            >
              登録する
            </b-button>
            <div
              v-if="status == 2"
              class="text-center mt-2"
            >
              <b-spinner
                style="width: 3rem; height: 3rem;"
                class="spinner_"
                variant="primary"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import createUserAccount from '@/firestore/authentication/create'
import registerUser from '@/firestore/authentication/register'
import sendResisteredMail from '@/firestore/authentication/sendResisteredMail'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import store from '@/store'
import firebase from 'firebase/app'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    // VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    // BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      errorMSG: '',
      required,
      email,
      status: 1,
      password: '',
    }
  },
  computed: {
    ...mapGetters('verifyEmail', ['mailaddress']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
  },
  methods: {
    async register() {
      this.status = 2
      const data = {
        mailaddress: this.mailaddress,
        password: this.password,
        userType: this.$route.query.type || 'consumer',
      }
      const response = await createUserAccount(data)
      if (response.status === 'success') {
        await this.registerUserInfo(data)
      } else {
        this.errorMSG = response.msg
        this.status = 1
        window.console.log('error', response.msg)
      }
    },
    async registerUserInfo(data) {
      const response = await registerUser(data)
      if (response.status === 'success') {
        await this.sendMail(data)
      } else {
        this.errorMSG = response.msg
        this.status = 1
        window.console.log('error', response.msg)
      }
    },
    async sendMail(data) {
      const response = await sendResisteredMail(data)
      if (response.status === 'success') {
        await this.login()
        // this.$router.push({ name: 'student-profile' })
      } else {
        this.errorMSG = response.msg
        this.status = 1
        window.console.log('error', response.msg)
      }
    },
    async login() {
      const isLogined = await firebase.auth().signInWithEmailAndPassword(this.mailaddress, this.password)
        .then(
          res => {
            res.user.getIdToken().then(idToken => {
              localStorage.setItem('jwt', idToken.toString())
              useJwt.setToken(idToken.toString())
              store.dispatch('userBasic/updateUid', res.user.uid)
              store.dispatch('userBasic/updateMail', res.user.email)
            })
            return true
          },
        )

      if (isLogined) {
        await firebase.firestore().collection('user')
          .where('mail', '==', this.mailaddress)
          .get()
          .then(sn => {
            if (!sn.empty) {
              sn.forEach(doc => {
                const data = doc.data()
                /* eslint-disable */
                const userData = {
                  "id": data.uid,
                  "email": data.mail,
                  "role": "admin",
                  "ability":[{"action":"manage",
                  "subject":"all"}],
                  "extras":{"eCommerceCartItemsCount":5},
                }
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.ability)
                store.dispatch('userBasic/updateUserType', data.type)
                // ユーザタイプによって分ける：①コレクト→管理画面、②一般→ダッシュボード
                this.$router.replace(getHomeRouteForLoggedInUser(data.type))
                this.status = 1
                /* eslint-enable */
              })
            }
          })
      }
    },
    login_old() {
      useJwt.login({
        email: 'admin@demo.com',
        password: 'admin',
      })
        .then(response => {
          const { userData } = response.data
          useJwt.setToken(response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          store.dispatch('userBasic/updateUserType', this.$route.query.type)
          this.$router.replace(getHomeRouteForLoggedInUser(this.$route.query.type))
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.spinner_ {
  margin: 3% auto;
}
.input_ {
  font-size: 1.4rem;
  letter-spacing: 3px;
}
</style>
