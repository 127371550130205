import firebase from 'firebase/app'
import store from '@/store'

export default async function registerUser(payload) {
  const { mailaddress, userType } = payload

  try {
    const user = await firebase.auth().currentUser
    store.dispatch('userBasic/updateUid', user.uid)
    store.dispatch('userBasic/updateMail', user.email)
    store.dispatch('userBasic/updateUserType', userType)

    const d = {
      docID: user.uid,
      insertTime: new Date(),
      type: userType,
      mail: mailaddress,
      displayStatus: 'active',
      uid: user.uid,
      isUserProfile: false,
    }

    await firebase.firestore().collection('user').doc(user.uid).set(d, { merge: true })

    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: 'ユーザ登録が正常に登録されませんでした。',
    }
    return response
  }
}
